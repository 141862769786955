<template>
  <auth-layout>
    <page-section class="flex-1 flex flex-col justify-center items-center">
      <v-card class="mx-auto max-w-lg p-5 text-center">
        <v-card-body>
          <h2 class="text-3xl font-bold">Detalii firma</h2>
          <form
            class="text-left"
            action=""
            method="POST"
            @submit.prevent="onSubmit"
            @keydown="form.errors.clear($event.target.name)"
          >
            <div class="mb-4" v-text="form.message"></div>
            <div class="mb-4">
              <v-label for="fiscal_code"> Cod fiscal </v-label>
              <v-input
                type="text"
                name="fiscal_code"
                v-model="form.fiscal_code"
              ></v-input>
              <v-error :error="form.errors.get('fiscal_code')" />
            </div>

            <div class="mb-4">
              <v-label for="registration_number"> Numar inregistrare </v-label>
              <v-input
                type="text"
                name="registration_number"
                v-model="form.registration_number"
              ></v-input>
              <v-error :error="form.errors.get('registration_number')" />
            </div>

            <div class="mb-4">
              <v-label for="name"> Nume firma </v-label>
              <v-input type="text" name="name" v-model="form.name"></v-input>
              <v-error :error="form.errors.get('name')" />
            </div>
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Sediu social
              </legend>

              <div class="mb-4">
                <v-label for="street"> Strada </v-label>
                <v-input
                  type="text"
                  name="street"
                  v-model="form.street"
                ></v-input>
                <v-error :error="form.errors.get('street')" />
              </div>
              <div class="mb-4">
                <v-label for="locality"> Localitate </v-label>
                <v-input
                  type="text"
                  name="locality"
                  v-model="form.locality"
                ></v-input>
                <v-error :error="form.errors.get('locality')" />
              </div>
              <div class="mb-4">
                <v-label for="locality"> Cod Postal </v-label>
                <v-input
                  type="text"
                  name="postal_code"
                  v-model="form.postal_code"
                ></v-input>
                <v-error :error="form.errors.get('postal_code')" />
              </div>
              <div class="mb-4">
                <v-label for="region"> Judet </v-label>
                <v-input
                  type="text"
                  name="region"
                  v-model="form.region"
                ></v-input>
                <v-error :error="form.errors.get('region')" />
              </div>
              <div class="mb-4">
                <v-label for="country_id"> Tara </v-label>
                <select-country
                  class="mt-1 w-full"
                  select-name="country_id"
                  v-model="form.country_id"
                />
                <v-error :error="form.errors.get('country_id')"></v-error>
              </div>
            </fieldset>

            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                Documente
              </legend>
              <div class="mb-4">
                <v-label for="attachment"> Incarca document </v-label>
                <v-input
                  type="file"
                  name="attachment"
                  @change="onFileChange"
                ></v-input>
                <v-error :error="form.errors.get('attachment')"></v-error>
              </div>
            </fieldset>

            <div class="text-right">
              <v-button-primary :disabled="form.loading">
                <v-button-icon v-if="form.loading">
                  <loader :radius="5" />
                </v-button-icon>
                Salveaza
              </v-button-primary>
            </div>
          </form>
        </v-card-body>
      </v-card>
    </page-section>
  </auth-layout>
</template>

<script>
import VCard from "@/components/VCard.vue";
import VInput from "@/components/VInput.vue";
import VLabel from "@/components/VLabel.vue";
import Form from "@/core/Form";
import vendorsApi from "@/api/resources/vendorsApi";
import VError from "@/components/VError.vue";
import AuthLayout from "../layout/AuthLayout.vue";
import SelectCountry from "../components/shared/SelectCountry.vue";
import VCardBody from "../components/VCardBody.vue";
import PageSection from "../components/PageSection.vue";
import VButtonPrimary from "../components/VButtonPrimary.vue";
import VButtonIcon from "../components/VButtonIcon.vue";
import Loader from "../components/Loader.vue";
import authService from "../services/authService";

export default {
  components: {
    VCard,
    VLabel,
    VInput,
    VError,
    AuthLayout,
    SelectCountry,
    VCardBody,
    PageSection,
    VButtonPrimary,
    VButtonIcon,
    Loader,
  },
  data() {
    return {
      form: new Form({
        name: "",
        region: "",
        fiscal_code: "",
        registration_number: "",
        street: "",
        locality: "",
        postal_code: "",
        country_id: "",
        attachment: "",
      }),
    };
  },
  methods: {
    onFileChange(e) {
      let files = Array.from(e.target.files || e.dataTransfer.files);
      this.form.attachment = files[0];
    },

    onSubmit() {
      this.form.submitService(vendorsApi, "store").then((response) => {
        console.log(response);
        authService.setVendorStatus(false);
        authService.setVendorId(response.data.id);
        this.form.setData(response.data);
        this.$router.push({ name: "TaxInformation" });
      });
    },
  },
};
</script>

<style></style>
